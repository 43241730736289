import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./css/employebuy.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/images/logo1.png";
import TickBuy from "../../assets/images/tickbuyinfo.png";
import { logEvent } from "../../utils/Analytics";
const EmployerBuyConnect = () => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const options = [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 50, label: "50" },
        { value: 80, label: "80" },
        { value: 100, label: "100" },
        { value: 150, label: "150" },
        { value: 200, label: "200" },
        { value: 250, label: "250" },
        { value: 300, label: "300" },
        { value: 400, label: "400" },
        { value: 500, label: "500" },
    ];
    const [dollaramount, setDollarAmount] = useState(0.25);
    console.log(dollaramount, "dollar amount");

    const fetchdollarInfo = async () => {
        try {
            const response = await axios.get("/api/admin/getdollaramountadmin");
            const { dollaramount } = response.data;
            setDollarAmount(dollaramount);
        } catch (error) {
            console.error("Error fetching", error);
        }
    };
    useEffect(() => {
        fetchdollarInfo();
    }, []);
    // const options = [
    //   { value: 10, label: `10 connects for $${(10 * dollaramount).toFixed(2)}` },
    //   { value: 20, label: `20 connects for $${(20 * dollaramount).toFixed(2)}` },
    //   { value: 40, label: `40 connects for $${(40 * dollaramount).toFixed(2)}` },
    //   { value: 50, label: `50 connects for $${(50 * dollaramount).toFixed(2)}` },
    //   { value: 80, label: `80 connects for $${(80 * dollaramount).toFixed(2)}` },
    //   { value: 100, label: `100 connects for $${(100 * dollaramount).toFixed(2)}` },
    //   { value: 150, label: `150 connects for $${(150 * dollaramount).toFixed(2)}` },
    //   { value: 200, label: `200 connects for $${(200 * dollaramount).toFixed(2)}` },
    //   { value: 250, label: `250 connects for $${(250 * dollaramount).toFixed(2)}` },
    //   { value: 300, label: `300 connects for $${(300 * dollaramount).toFixed(2)}` },
    //   { value: 400, label: `400 connects for $${(400 * dollaramount).toFixed(2)}` },
    //   { value: 500, label: `500 connects for $${(500 * dollaramount).toFixed(2)}` },
    // ];
    // const [usdAmount, setUsdAmount] = useState((options[0].value * dollaramount.toFixed(2)));
    // const [selectedOption, setSelectedOption] = useState(options[0]);

    const [usdAmount, setUsdAmount] = useState((options[0].value * dollaramount).toFixed(2));
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleOptionSelect = (selectedOption) => {
        setSelectedOption(selectedOption);
        const calculatedAmount = selectedOption.value * dollaramount;
        const formattedAmount = parseFloat(calculatedAmount.toFixed(2));

        setUsdAmount(formattedAmount % 1 === 0 ? formattedAmount.toFixed(0) : formattedAmount.toFixed(2));
    };

    // const getFormattedAmount = (amount) => {
    //   // Check if the amount has decimal places
    //   if (Number.isInteger(amount)) {
    //     // If no decimal places, return the amount without decimals
    //     return amount.toFixed(0);
    //   } else {
    //     // If decimal places, return the amount with up to 2 decimal places
    //     return amount.toFixed(2);
    //   }
    // };
    // const [usdAmount, setUsdAmount] = useState(getFormattedAmount(options[0].value * dollaramount));
    // const handleOptionSelect = (selectedOption) => {
    //   setSelectedOption(selectedOption);
    //   // setUsdAmount(selectedOption.value * dollaramount);
    //   setUsdAmount(getFormattedAmount(selectedOption.value * dollaramount));
    // };

    const handlePaymentwithRazorPay = async (price) => {
        if (user === null) {
            toast.error("Please Login to continue.");
            navigate("/login");
            return;
        }
        const token = localStorage.getItem("token");
        if (!token) {
            toast.error("Please Login to continue.");
            return;
        }
        const amountInDollars = parseFloat(price); // amount is in dollars
        const intAmount = Math.round(amountInDollars * 100);
        const userId = user && user?._id;
        try {
            // Initiate payment and get order details
            const dataa = {
                userName: user.name,
                amount: intAmount,
                number: user.phone,
                MUID: "MUID" + Date.now(),
                transactionId: "T" + Date.now(),
                currency: "USD",
                selectedOptionValue: selectedOption.value,
            };

            const response = await axios.post(`/api/employer/payment/razorpayPayment`, dataa, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            console.log(response, "response");
            if (response.status !== 200) {
                console.log("Clicked");
                toast.error("Failed to initialize payment.");
                return;
            }
            const data = response.data.data;
            // Initialize Razorpay with the order ID
            const options = {
                // key: 'rzp_test_LqUlSM0ezygXAa',
                key: "rzp_live_yXMJW9snS92LWU",
                amount: intAmount,
                currency: "USD",
                name: "Hirekingdom",
                description: "Purchase Description",
                order_id: data.id,
                handler: async function (response) {
                    console.log(response, "resss");
                    const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
                    const verifyResponse = await axios.post(`/api/employer/payment/razorpayVerify?&userId=${userId}`, {
                        razorpay_order_id,
                        razorpay_payment_id,
                        razorpay_signature,
                        intAmount,
                        selectedOptionValue: selectedOption.value,
                    });
                    if (verifyResponse.data.message === "Payment verified successfully") {
                        toast.success("Payment Success.");
                        navigate("/newpost/0");
                    } else {
                        toast.error("Failed to initialize payment.");
                        console.log("Failed to initialize payment line 151", verifyResponse);
                    }
                },
                prefill: {
                    contact: user.phone,
                    name: user.name,
                    email: user.email,
                },
                theme: {
                    color: "#3399cc",
                },
            };
            const razorpay = new window.Razorpay(options);
            razorpay.open();
            // new window.Razorpay(options).open();
        } catch (error) {
            toast.error("Failed to initialize payment.");
            console.log("Failed to initialize payment line 167", error);
        }
    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "200px",
            minHeight: "44px",
        }),
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            position: "absolute",
            zIndex: 9999,
        }),
        menuList: (provided, state) => ({
            ...provided,
            width: "100%",
        }),
    };

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`);
    };

    return (
        <>
            <div className="myapplicationsbuymain">
                <div className="my-applicationsbuyconnects">
                    <div className="my-applicationsbuyconnectsheader">
                        <img className="my-applicationsbuyconnectslogo" src={Logo} alt="logo" style={{ height: "50px", marginLeft: "24px", marginTop: "11px" }} />
                    </div>
                    <div className="my-applicationsbuyconnectsbody">
                        <div>
                            <div style={{ fontSize: "16px", fontWeight: "500", marginBottom: "11px" }}>Select Number of Connects</div>
                            <div className="selectspan">
                                <Select options={options} value={selectedOption} onChange={handleOptionSelect} styles={customStyles} menuPortalTarget={document.body} />
                                <span className="usdamountstyle"> USD: ${usdAmount}</span>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <button
                                className="employerbuttontobuy"
                                onClick={() => {
                                    handlePaymentwithRazorPay(usdAmount);
                                    handleAddEvent("Buy connects");
                                }}
                            >
                                Buy Connects
                            </button>
                            <p style={{ width: "100%", fontSize: "small", color: "#3AAD5D", textAlign: "center", marginTop: "9px" }}>
                                <img src={TickBuy} alt="Tick" style={{ height: "15px", width: "15px" }} />
                                After Successful Payment the Respective number of connects will be added to your account.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
};

export default EmployerBuyConnect;
