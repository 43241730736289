import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "../css/dialog.css";
import { BiMessage, BiRightArrowCircle } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import toast from "react-hot-toast";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";
function SimpleDialog(props) {
  const { candidates, appliedJobId, employerId, open, onClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const textareaRef = React.useRef(null);
  const handleClose = () => {
    setMessage("");
    setLoading(false);
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message && message.length > 3500) {
      textareaRef.current.focus();
      return;
    }
    else {
      setLoading(true);
      try {
        const response = await axios.post(
          "/api/employer/messagecandidate",
          { sender: employerId, receiver: candidates.map((i) => [i._id, i.user]), appliedJobId, message },
          {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          }
        );
        const data = await response.data;
        toast.success(data.message);
        setMessage("");
      } catch (err) {
        toast.error("Unable to send message");
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  return (
    <Dialog className="dialog" onClose={handleClose} open={open}>
      <div className="dialog-container">
        <div className="title">
          <BiMessage /> Hirekingdom Chat
          <span style={{ cursor: "pointer" }} onClick={handleClose}>
            <RxCross1 />
          </span>
        </div>

        <div className="candidate-list">
          <span>Message to:</span>
          {candidates.slice(0, 6).map((candidate) => (
            <span>
              {candidate?.resume?.PersonalDetails?.firstName} {candidate?.resume?.PersonalDetails?.lastName}
            </span>

          ))}
          <div style={{ background: "none", padding: "0px 0px", marginTop: "10px" }}>{candidates.length > 6 && `... ${candidates.length - 6} More`}</div>
        </div>

        <form onSubmit={handleSubmit} className="w-100">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write a message..."
            className="w-100"
            name=""
            rows="10"
            style={{ borderColor: message && message?.length > 3500 ? 'red' : '', paddingLeft: '20px' }}
            ref={textareaRef}
          ></textarea>
          {message && message?.length > 3500 ?
            <p className='text-end' style={{ fontSize: "13px", color: "red" }}>Please limit your input to 3500 characters or less</p>
            : ""
          }
          {/* <span>Max file size: 10MB. File type allowed: zip, pdf, doc, docx, jpeg, jpg, png</span> */}
          <button type="submit" disabled={loading} className="send-btn">
            {loading ? (
              <>
                Sending Message <FaSpinner className="spinner" /> 
              </>
            ) : (
              "Send Message"
            )}
          </button>

        </form>
      </div>
    </Dialog>
  );
}

export default SimpleDialog;
