import React from "react";
import "./PreviewJob.scss";
import home from "../../assets/images/home.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { MdOutlineCheckCircle } from "react-icons/md";
import { HiArrowTrendingUp } from "react-icons/hi2";
import { SlLocationPin } from "react-icons/sl";
import { BiBriefcaseAlt } from "react-icons/bi";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { MONTH_DETAILS } from "../data";
import axios from "axios";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const PreviewJob = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const userType = localStorage.getItem("userType");
    const [filled, setFilled] = useState(false);
    const [state2, setState2] = useState(null); // Assuming state2 is your state variable
    const [canonicalUrl, setCanonicalUrl] = useState("");
    const { jobId } = useParams();

    // Extracting the ID part after the last underscore
    const jobId2 = jobId.substring(jobId.lastIndexOf("_") + 1);
    console.log("whole url",window.location.origin)
    const openPreviewPageInNewTab = (state) => {
        const data = state;
        const url = `/preview/${data?.profile?.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data?.opportunityType}-at-${data?.employer?.organisationName?.replace(/ /g, "-")}_${data?._id}`.toLowerCase();
        setCanonicalUrl(`${window.location.origin}${url}`);
        return url
    };
    const queryParams = new URLSearchParams(location.search);
    const companyName = queryParams.get("name"); // Decodes the name

      useEffect(() => {
          const fetchData = async () => {
              try {
                  const { data } = await axios.post("/api/user/previewdetails", {
                      Id: jobId2,
                    });
                    setState2(data);
                    openPreviewPageInNewTab(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [jobId]);


    const handleSaveJob = async (jobId) => {
        try {
            const { data } = await axios.get(`/api/user/saveJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            if (data.success) {
                setFilled(!filled);
                // window.location.reload();
                dispatch(loadUser());
                toast.success("Job saved");
            }
        } catch (error) {}
    };

    useEffect(() => {
        dispatch(loadUser());
    }, []);
    // const iconStyle = {
    //   position: 'absolute',
    //   top: '10px',
    //   right: '10px',
    //   fontSize: '18px',
    //   color: filled ? 'red' : '',
    //   cursor: 'pointer'
    // };

    const handleRemoveJob = async (jobId) => {
        try {
            const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            if (data.success) {
                setFilled((prevFilled) => !prevFilled);
                //     window.location.reload();
                dispatch(loadUser());
                toast.success("Job removed");
            }
        } catch (error) {}
    };

    const formatNumberWithCommas = (number) => {
        // Convert number to string
        let strNumber = number.toString();

        // Split the string into parts (crores, lakhs, thousands, and hundreds)
        let crores = strNumber.slice(0, -7);
        let lakhs = strNumber.slice(-7, -5);
        let thousands = strNumber.slice(-5, -3);
        let hundreds = strNumber.slice(-3);

        // Join the parts with commas
        return crores + (crores ? "," : "") + lakhs + (lakhs ? "," : "") + thousands + "," + hundreds;
    };

    const calculateDaysDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = end - start;

        // Convert the difference to days
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        // Set the state2 with the calculated difference
        return differenceInDays;
    };

    const navigate = useNavigate();
    const { state } = useLocation();
    const state1 = state ? state : JSON.parse(localStorage.getItem("viewJob"));
    const { user } = useSelector((state) => state.user);

    const viewCount = async () => {
        const id = state2 && state2._id;
        try {
            await axios.post("/api/user/viewcount", { id });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
        viewCount();
    }, []);

    useEffect(() => {
        viewCount();
    }, [state2]);
    const currentDate = new Date();

    // Get date 30 days from now
    const next30Days = new Date();
    next30Days.setDate(next30Days.getDate() + 30);

    // Function to format the date as "dayth Month'yy"
    const formatDate = (date) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear().toString().substr(-2);
        return `${day}${getDaySuffix(day)} ${month}'${year}`;
    };

    // Function to get the suffix for the day
    const getDaySuffix = (day) => {
        if (day >= 11 && day <= 13) {
            return "th";
        }
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    // Format current date and date 30 days from now
    const formattedCurrentDate = formatDate(currentDate);
    const formattedNext30Days = formatDate(next30Days);

    const formatDate2 = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear().toString().substr(-2);
        return `${day}${getDaySuffix2(day)} ${month}'${year}`;
    };

    const getDaySuffix2 = (day) => {
        if (day >= 11 && day <= 13) {
            return "th";
        }
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    return (
        <div className="reviewpage1 container-fluid px-4 px-md-5 py-5" style={{ backgroundcolor: "white" }}>
            {state2 ? (
                <>
               
                    <Helmet>
                        <meta property="og:title" content={`${state2.profile} ${state2.jobType === "Remote" ? state2.jobType + " " : ""} ${state2.opportunityType}`} />
                        <title>{`${state2.profile} ${state2.jobType === "Remote" ? state2.jobType + " " : ""} ${state2.opportunityType}`}</title>
                        <link rel="canonical" href={canonicalUrl} />
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "JobPosting",
                                "title": state2.profile,
                                "employmentType": state2.jobType,
                                "description": state2.jobDescription,
                                "datePosted": state2.datePosted,
                                "hiringOrganization": {
                                    "@type": "Organization",
                                    "name": state2.employer.organisationName,
                                    "sameAs": state2.companyWebsite
                                },
                                "jobLocation": state2.jobType !== "Remote" ? {
                                    "@type": "Place",
                                    "address": {
                                        "@type": "PostalAddress",
                                        "addressLocality": state2.city,
                                        "addressCountry": state2.employer.organisationURL
                                    }
                                } : "Remote"
                            })}
                        </script>
                    </Helmet>
                    <title>{state2.profile + " " + (state2.jobType === "Remote" ? state2.jobType + " " : " ") + state2.opportunityType}</title>

                    <h1 className="text-center pt-5 jobInternshipTitle" >
                        {state2.profile}
                        {state2.jobType === "Remote" ? <> work from home</> : <></>} {state2.opportunityType.toLowerCase()} {/*at {state2.organisationName}*/}
                        {state2.jobType === "Remote" ? <></> : <> {state2.city && state2.city.length > 0 ? state2.city.map((city) => "in" + " " + city + " ") : ""}</>}
                    </h1>
                    <div className="r1">
                        <div className="sectionn11 mb-3 border">
                            <div
                                className="mt-3"
                                style={{
                                    width: "124px",
                                    borderRadius: "5px",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    padding: "3px",
                                    color: "#787878",
                                    border: "solid 1px #D1D1D1",
                                }}
                            >
                                <img src={arrowup} alt="" style={{ marginRight: "5px", height: "17px", marginTop: "-4px" }} />
                                Actively hiring
                            </div>
                            <div className="row">
                                {/* <div className="col-9"> */}
                                <div className="responsive-col">
                                    <h3 style={{ fontSize: "17px" }} className="title pt-3">
                                        {state2.profile}
                                    </h3>
                                    <h4 style={{ fontSize: "15px", color: "gray", cursor: "pointer" }} className="companyname pt-2" 
                                    onClick={() =>
                                        navigate(
                                          `/company-detail/${state2?.employer?.organisationName.replace(/\s+/g, "-")}/${state2?.employer?._id}`,
                                          { state: state2.employer }
                                        )
                                      }>
                                        {state2.employer.organisationName}
                                    </h4>
                                </div>
                                <div className="col-3 text-end ">{state2.employer.organisationAvatar && <img src={state2.employer.organisationAvatar.url} alt="" className="OrgLogoAvatar mr-2" />}</div>
                            </div>

                            <div className="content mb-5" style={{ marginTop: "20px" }}>
                                <span>
                                    {state2.jobType === "Remote" ? (
                                        <>
                                            <img src={home} alt="Engineering" style={{ width: "14px", marginTop: "-2px" }} />
                                            <span className="wfm1 mx-2">Work From Home</span>
                                        </>
                                    ) : (
                                        <>
                                            {state2.city.map((data) => (
                                                <>
                                                    <SlLocationPin
                                                        style={{
                                                            color: "gray",
                                                            fontSize: "15px",
                                                            marginBottom: "3px",
                                                            marginTop: "-2px",
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "15px",
                                                            marginRight: "20px",
                                                            marginTop: "-2px",
                                                        }}
                                                        className="wfm1 ml-2"
                                                    >
                                                        {data}
                                                    </span>
                                                </>
                                            ))}
                                        </>
                                    )}
                                </span>
                                <div className="py-4 " style={{ display: "flex", flexWrap: "wrap", gap: "20px 70px" }}>
                                    <div className="statusBoxes">
                                        <p>
                                            <span>
                                                <img src={circle} alt="" style={{ width: "14px" }} />
                                            </span>
                                            <span className="mx-2 heeading">START DATE</span>
                                        </p>
                                        <h3 className="head">{state2.startDate == "Immediately (within next 30 days)" ? <>Immediately</> : <>{state2.startDate}</>}</h3>
                                    </div>

                                    {state2.opportunityType !== "Job" ? (
                                        <div className="statusBoxes">
                                            <p>
                                                <span>
                                                    <img src={calendar} alt="" style={{ width: "14px" }} />
                                                </span>
                                                <span className="mx-2 heeading">DURATION</span>
                                            </p>
                                            <h3 className="head">
                                                {state2.duration} {(state2?.durationType === "month" && state2.duration > 1 ? "months" : state2?.durationType) || "months"}
                                            </h3>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="statusBoxes">
                                                <p>
                                                    <span>
                                                        <img src={money} alt="" style={{ width: "14px" }} />
                                                    </span>
                                                    <span className="mx-2 heeading">CTC (ANNUAL)</span>
                                                </p>
                                                {state2.salary == 0 ? (
                                                    <>
                                                        <h3 className="head">Unpaid</h3>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                            ₹ {formatNumberWithCommas(state2.salary)}
                                                            {state2.salaryMax ? <> - {formatNumberWithCommas(state2.salaryMax)}</> : <></>}
                                                        </h3>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {state2.opportunityType === "Job" ? (
                                        <>
                                            <div className="statusBoxes">
                                                <p>
                                                    <span>
                                                        <BiBriefcaseAlt style={{ color: "#9b9b9b", fontSize: "15px" }} />
                                                    </span>
                                                    <span className="mx-2 heeading">EXPERIENCE</span>
                                                </p>
                                                <h3 className="head">
                                                    {state2.minExperience}
                                                    {state2.maxExperience ? -state2.maxExperience : <></>} years
                                                </h3>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="statusBoxes">
                                                <p>
                                                    <span>
                                                        <img src={money} alt="" style={{ width: "14px" }} />
                                                    </span>
                                                    <span className="mx-2 heeading">STIPEND</span>
                                                </p>
                                                {state2.salary == 0 ? (
                                                    <>
                                                        <h3 className="head">Unpaid</h3>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3 className="head"> ₹ {formatNumberWithCommas(state2.salary)} /month</h3>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    <div className="statusBoxes">
                                        <p>
                                            <span>
                                                <img src={game} alt="" style={{ width: "14px" }} />
                                            </span>
                                            <span className="mx-2 heeading">Apply by</span>
                                        </p>
                                        <h3 className="head">{state2.deadline.slice(8, 10) + " " + MONTH_DETAILS[parseInt(state2.deadline.slice(5, 7), 10)] + "' " + state2.deadline.slice(2, 4)}</h3>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3"></div>
                                </div>
                                <div className="row py-2 px-1">
                                    <span>
                                        <span>
                                            {calculateDaysDifference(state2.datePosted).toFixed(0) < 6 ? (
                                                <>
                                                    {calculateDaysDifference(state2.datePosted).toFixed(0) == 0 ? (
                                                        <span className=" post1" style={{ backgroundColor: "#E1FFE1", color: "green" }}>
                                                            <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted just now
                                                        </span>
                                                    ) : (
                                                        <span className=" post1" style={{ backgroundColor: "#E1FFE1", color: "green" }}>
                                                            <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted {calculateDaysDifference(state2.datePosted).toFixed(0)} days ago
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {calculateDaysDifference(state2.datePosted).toFixed(0) > 5 && calculateDaysDifference(state2.datePosted).toFixed(0) < 11 ? (
                                                        <>
                                                            <span
                                                                className=" post1 "
                                                                style={{
                                                                    backgroundColor: "#E2F8FF",
                                                                    color: "#0D8CB8",
                                                                }}
                                                            >
                                                                <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted {calculateDaysDifference(state2.datePosted).toFixed(0)} days ago
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className=" post1 ">
                                                                <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted {calculateDaysDifference(state2.datePosted).toFixed(0)} days ago
                                                            </span>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </span>{" "}
                                        <span className="post1" style={{ marginLeft: "10px" }}>
                                            {state2.opportunityType}
                                        </span>
                                        {state2.isPartTimeAllowed == "Yes" ? (
                                            <span className="post1" style={{ marginLeft: "10px" }}>
                                                Part time
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                    <div className="row">
                                        <div className="col">
                                            <p className="mt-5" style={{ width: "max-content" }}>
                                                <FiUsers style={{ fontSize: "22px", color: "#9b9b9b", marginTop: "-3px" }} />
                                                <span className="head" style={{ fontSize: "15px" }}>
                                                    {state2.userApplied && state2.userApplied.length > 0 ? <>{state2.userApplied.length}</> : <>0</>} applicants
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col">
                                            <div className="mt-5 text-end">
                                                {userType && userType == "User" ? (
                                                    <>
                                                        <FaBookmark
                                                            style={{
                                                                position: "absolute",
                                                                top: "20px",
                                                                right: "10px",
                                                                fontSize: "20px",
                                                                color: user && user.saveJob.includes(state2._id) ? "#0991DA" : "white",
                                                                cursor: "pointer",
                                                                marginTop: "10px",
                                                            }}
                                                            onClick={() => {
                                                                if (user && user.saveJob.includes(state2._id)) {
                                                                    handleRemoveJob(state2._id);
                                                                } else {
                                                                    handleSaveJob(state2._id);
                                                                }
                                                            }}
                                                        />

                                                        <FaRegBookmark
                                                            style={{
                                                                position: "absolute",
                                                                top: "20px",
                                                                right: "10px",
                                                                fontSize: "20px",
                                                                color: "#0991DA",
                                                                cursor: "pointer",
                                                                marginTop: "10px",
                                                            }}
                                                            onClick={() => {
                                                                if (user && user.saveJob.includes(state2._id)) {
                                                                    handleRemoveJob(state2._id);
                                                                } else {
                                                                    handleSaveJob(state2._id);
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="" style={{ height: "0.5px", color: "#C4C4C4", marginTop: "-20px" }}></hr>

                            <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                                About the {state2 && state2.opportunityType == "Job" ? "job" : "internship"}
                            </h3>
                            {/* <span className="wfm123 mt-4  pt-3">Key responsibilities:</span>  */}
                            <p className="wfm123" style={{ marginTop: "20px", whiteSpace: "pre-wrap", lineHeight: "25px" }}>
                                {state2.jobDescription}
                            </p>

                            <div className="mt-5">
                                <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                                    Skill(s) required
                                </h3>
                                {state2.skill.map((data, index) => (
                                    <p className="post1234 mr-4 mt-3">{data}</p>
                                ))}
                            </div>

                            <h3 className="title pt-2 mt-5" style={{ fontSize: "17px" }}>
                                Who can apply
                            </h3>
                            <p className="wfm123" style={{ marginTop: "20px" }}>
                                Only those candidates can apply who :
                            </p>
                            <p className="wfm123">
                                1. are available for the {state2.jobType === "Remote" ? <>work from home</> : <></>} {state2.opportunityType.toLowerCase()} {state2.jobType === "Remote" ? <></> : <>{state2.city && state2.city.length > 0 ? <>in {state2.city[0].toLowerCase()}</> : <></>}</>}
                            </p>
                            <p className="wfm123">
                                2. can start the{" "}
                                {/* {state2.jobType === 'Remote' ? <>work from home</> : <></>}{' '}
            {state2.opportunityType.toLowerCase()}{' '} */}
                                {state2.jobType === "Remote" ? <>work from home</> : <></>} {state2.opportunityType.toLowerCase()}{" "}
                                {state2.startDate === "Immediately (within next 30 days)" ? (
                                    <>
                                        {formattedCurrentDate} and {formattedNext30Days}
                                    </>
                                ) : state2.startDate === "Later" ? (
                                    <>internship between {state2.internshipFrom && state2.internshipTo ? `${formatDate2(state2.internshipFrom)} and ${formatDate2(state2.internshipTo)}` : ""}</>
                                ) : null}
                                {state2.jobType === "Remote" ? <></> : <>{state2.city && state2.city.length > 0 ? <> in {state2.city[0].toLowerCase()}</> : <></>}</>} {/* {state2.startDate.toLowerCase()} */}
                            </p>
                            <p className="wfm123">3. have relevant skills and interests</p>
                            {state2.opportunityType == "Internship" ? <p className="wfm123">4. are available for duration of {state2.duration} months</p> : <></>}
                            <p className="wfm123">* Women wanting to start/restart their career can also apply.</p>

                            {state2?.preference && state2.preference.length > 0 ? (
                                <div className="mt-5 ">
                                    <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                                        Additional Candidate Preferences
                                    </h3>
                                    <p className="wfm123" style={{ marginTop: "20px", whiteSpace: "pre-wrap", lineHeight: "25px" }}>
                                        {state2.preference}
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {state2.perks && state2.perks.length > 0 ? (
                                <div className="mt-5 ">
                                    <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                                        Perks
                                    </h3>
                                    {state2.perks.map((data) => (
                                        <p className=" post1234 mr-4 mt-3">{data}</p>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="mt-5">
                                <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                                    Number of opening
                                </h3>
                                <p className="wfm123 mt-4">{state2.numberOfOpeining}</p>
                            </div>

                            <div className="mt-5">
                                <h3 className="title pt-2" style={{ fontSize: "17px" }}>
                                    {" "}
                                    About {state2.organisationName}
                                </h3>
                                <p className="wfm123 mt-4" style={{ lineHeight: "20px", whiteSpace: "pre-wrap" }}>
                                    {state2.organisationDescription}
                                </p>
                            </div>

                            <div className="content mt-5 mb-3">
                                {user ? (
                                    user.role !== "Employer" && (
                                        <div style={{ textAlign: "center" }}>
                                            {state2.userApplied.includes(user ? user._id : "") ? (
                                                <>
                                                    <p
                                                        className=" py-3 mx-auto"
                                                        style={{
                                                            color: "green",
                                                            fontWeight: "400",
                                                            marginTop: "4px",
                                                            borderRadius: "10px",
                                                            width: "120px",
                                                        }}
                                                    >
                                                        <MdOutlineCheckCircle
                                                            style={{
                                                                marginRight: "3px",
                                                                fontSize: "22px",
                                                                marginTop: "-2px",
                                                            }}
                                                        />
                                                        Applied
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    {/* <button
                        onClick={() =>
                          navigate('/coverletterpage', { state: state2 })
                        }
                        className="btn lastbutton"
                      >
                        Apply Now
                      </button> */}
                                                    <button
                                                        onClick={() => {
                                                            const { phone, city, resume } = user;

                                                            if (!phone && !city && !resume) {
                                                                navigate("/addResume");
                                                            } else if ((phone || city) && !resume) {
                                                                navigate("/addResume");
                                                            } else {
                                                                // navigate("/coverLetterPage", { state: state2 });
                                                                const jobId = `${state2.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${state2.opportunityType}-at-${state2.employer.organisationName.replace(/ /g, "-")}_${state2._id}`.toLowerCase();
                                                                const encodedJobId = encodeURIComponent(jobId);
                                                                navigate(`/coverletterpage/${encodedJobId}`, { state: state2 });
                                                            }
                                                        }}
                                                        className="btn lastbutton"
                                                        style={{ paddingTop: "7px", paddingBottom: "7px" }}
                                                    >
                                                        Apply Now
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )
                                ) : (
                                    <>
                                        <div style={{ textAlign: "center" }}>
                                            <button
                                                // onClick={() => navigate('/login')}
                                                onClick={() => navigate("/login", { state: { from: location.pathname } })}
                                                className="btn lastbutton"
                                                style={{ paddingTop: "7px", paddingBottom: "7px" }}
                                            >
                                                Apply Now
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ height: "100vh" }}></div>
                </>
            )}
        </div>
    );
};

export default PreviewJob;
