import React, { useEffect, useState, useRef } from "react";
import "./css/editProfile123.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import photo from "../../assets/images/icons/profile.png";
import { FiUsers } from "react-icons/fi";
import { CgOrganisation } from "react-icons/cg";
import { Avatar } from "@mui/material";
import FadeLoader from "react-spinners/FadeLoader";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from '../../actions/userActions';
import { logEvent } from "../../utils/Analytics";
const EditProfileCompany = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);


    useEffect(() => {
      if(user && !user.organisationName && !user.organisationDescription && !user.organisationURL){
        setEditable(true);
        document.querySelector(".sub-section123 input[type='text']:first-of-type").focus();
      }
      
    }, [user]);

  const [organisation, setOrganisation] = useState({
    email: "",
    organisationName: "",
    organisationDescription: "",
    organisationAvatar: "",
    // organisationURL: user?.organisationURL || "http://example.com/",
    organisationURL: user?.organisationURL || "",
  });

  const [logo, setLogo] = useState({
    orgLogo: "",
  });

  useEffect(() => {
    setOrganisation({
      ...organisation,
      email: user && user.email,
      organisationName: user && user.organisationName,
      organisationDescription: user && user.organisationDescription,
      organisationAvatar: "",
      organisationURL: user?.organisationURL || organisation.organisationURL,
    });

    setLogo({
      ...logo,
      orgLogo: (user && user.organisationAvatar != undefined) || "" ? user.organisationAvatar.url : "",
    });
  }, [user]);

  const handleOrganisationChange = (e) => {
    setOrganisation({
      ...organisation,
      email: user.email,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        if (Reader.result) {
          setOrganisation({
            ...organisation,

            organisationAvatar: Reader.result,
          });

          setLogo({
            ...logo,
            orgLogo: Reader.result,
          });
        }
      }
    };
  };

  const handleUpate = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (!isValidURL(organisation.organisationURL)) {
        toast.error("Invalid URL. Please enter a valid URL");
        setLoading(false);
        return;
      }
      if (!organisation.organisationURL.trim()) {
        toast.error("Organisation URL cannot be empty");
        setLoading(false);
        return;
      }
      const response = await axios.post("/api/user/updateorg", organisation);
      const data = await response.data;
      handleAddEvent("Update company details")
      if (response.status === 200) {
        setLoading(false);
        toast.success("Organisation Details saved");
        dispatch(loadUser());
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unable to update details");
    }
  };
  // const isValidURL = (url) => {
  //   // Regular expression to validate URL
  //   const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  //   return urlRegex.test(url);
  // };
  const isValidURL = (url) => {
    // Regular expression to validate URL
    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,2}[a-zA-Z]{2,}(\/)?$/;
    return urlRegex.test(url);
  };
  // const isValidURL = (url) => {
  //   // Regular expression to validate URL
  //   const urlRegex = /^(https?:\/\/)?(www\.[^w]|w{3}\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/)?$/;
  //   return urlRegex.test(url);
  // };

  const [type, setType] = useState("button");
    const handleAddEvent = (str) => {
            logEvent("Button", `Clicked ${str} button`);
        };

  return (<>
  
    <div style={{ backgroundColor: "white", height: "100%", width: "100%", paddingTop: "50px", paddingBottom: "50px" }}>
      <form onSubmit={handleUpate} className="profile-section123">
        <div className="d-flex align-items-center justify-content-center">
          <a href="/myProfile" onClick={() => handleAddEvent("Employer profile")}>
            <div className="logo">
              <FiUsers />
            </div>
          </a>
          <div style={{ backgroundColor: "#00a5ec", width: "120px", height: "4px" }}></div>
          <a href="/myProfile/company" onClick={() => handleAddEvent("Company profile")}>
            <div className="logo">
              <CgOrganisation />
            </div>
          </a>
        </div>

        <div className="d-flex align-items-center justify-content-center gap-5 mt-3">
          <span className="profile-section123-span">Personal Details</span>
          <span className="profile-section123-span">Organization Details</span>
        </div>

        {/* Organisation Details Section */}
        <h2 className="my-5 profile-section123-heading">
          Organization Details
        </h2>

        {!editable ? (
          <button
            onClick={() => {
              setEditable(true);
              document.querySelector(".sub-section123 input[type='text']:first-of-type").focus();
            }}
            className="submit-btn123 mb-4"
            type="button"
          >
            Edit
          </button>
        ) : (<></>)}

        <div className="sub-section123">
          <div className="details w-100">
            <div className="title">Organization Name</div>
            <input
              style={{ width: "100%", border: "1px solid #ddd", padding: "8px", backgroundColor: `${editable ? "white" : ""}` }}
              type="text"
              value={organisation?.organisationName}
              name="organisationName"
              onChange={handleOrganisationChange}
              onMouseOver="this.style.border='1px solid #00a5ec'"
              onMouseOut="this.style.border='1px solid #ddd'"
              placeholder="Organisation Name..."
              required
              disabled={!editable}
            />
          </div>

          <div className="details w-100">
            <div className="title">Organization description</div>
            <textarea
              minLength={50}
              style={{ height: "fit-content", backgroundColor: `${editable ? "white" : ""}`, width: "100%", border: "1px solid #ddd",  padding: "8px" }}
              type="text"
              placeholder="Description..."
              value={organisation?.organisationDescription}
              name="organisationDescription"
              onChange={handleOrganisationChange}
              rows={10}
              required
              onMouseOver="this.style.border='1px solid #00a5ec'"
              onMouseOut="this.style.border='1px solid #ddd'"
              disabled={!editable}
            ></textarea>
          </div>

          <div className="details w-100">
            <div className="title">Organization's website</div>
            <input
              style={{ padding: "8px", width: "100%", border: "1px solid #ddd", backgroundColor: `${editable ? "white" : ""}` }}
              type="text"
              value={organisation?.organisationURL}
              name="organisationURL"
              onChange={handleOrganisationChange}
              onMouseOver="this.style.border='1px solid #00a5ec'"
              onMouseOut="this.style.border='1px solid #ddd'"
              placeholder="Link to your website"
              disabled={!editable}
            />
          </div>

          <div className="details">
            <div htmlFor="logo" className="title">
              Organization logo{" "}
              <span className="small" style={{ color: "#8a8a8a", fontSize: "12px" }}>
                (Recommended)
              </span>
            </div>
            <div className="image-container p-4">
              <Avatar src={logo && logo.orgLogo} alt="User Avatar" sx={{ height: "100px", width: "auto",'@media (max-width: 450px)': { 
      height: "auto",
    } }} variant="square" />
            </div>
            {editable?(<>
            <label htmlFor="fileInput" className="custom-label">
            Change Logo
            </label>
            </>):(<>
            <label className="custom-labeldesabled">
            Change Logo
          </label>
            </>)}
          
            <span style={{ color: "#8a8a8a", fontSize: "14px", fontFamily: "'Inter var',sans-serif" }}>
              Max file size: 1Mb and max resolution: 500px x 500px. File type: jpeg, jpg, png, gif, bmp
            </span>
            <input
              onChange={handleImageChange}
              type="file"
              id="fileInput"
              className="hidden-input"
              accept=".jpeg,.jpg,.png,.gif,.bmp"
              multiple="false"
              data-show-preview="false"
              data-show-upload="false"
              aria-invalid="false"
              capture="camera"
              // value={logo}
            />
          </div>
        </div>

        <div className="website">
          <div className="title mx-3">Organization verified</div>
          <div className="sub-title mx-3">
            Organization's website:{"  "}
            <a target="__blank" href={organisation.organisationURL}>
              {organisation.organisationURL}
            </a>
          </div>
        </div>
        {loading ? (
          <>
            <div className="" style={{ textAlign: "center", marginBottom: "-20px" }}>
            {/*  <FadeLoader
                color={"#4B92C8"}
                loading={true}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
                style={{ marginLeft: "27px" }}
              />*/}

              <FadeLoader color="#B3B3B3"
              />
            </div>
          </>
        ) : (
          <></>)}
        {editable ? (<>
          {loading ? (<>
            <button style={{ backgroundColor: "#B3B3B3", border: "1px solid #B3B3B3"}} className="submit-btn123 mb-4" type="button">
              Save
            </button></>) : (<>
              <button className="submit-btn123 mb-4" type="button" onClick={handleUpate}>
                Save
              </button></>)

          }
        </>
        ) : (<></>)}

      </form>
    </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
 </>);
};

export default EditProfileCompany;
