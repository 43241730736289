import React, { useState, useRef, useEffect } from "react";
import filterLogo from "../../assets/images/Group.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { useLocation } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineCheckCircle } from "react-icons/md";
import { State } from "country-state-city";
import PropagateLoader from "react-spinners/PropagateLoader";
import home from "../../assets/images/home.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import { SlLocationPin } from "react-icons/sl";
import { BiBriefcaseAlt } from "react-icons/bi";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { LuFilter } from "react-icons/lu";
import citiesData from "../Employer/citiesData.json";
import { IoSearch } from "react-icons/io5";
import "../Student_New/Filter.css";
import Dropdown from "./Dropdown";
import { FaMagnifyingGlass } from "react-icons/fa6";

function CompanyDetail() {
    const location = useLocation();
    const dispatch = useDispatch();

    const [jobData, setJobData] = useState("");
    const [employerData, setEmployerData] = useState("");
    useEffect(() => {
        dispatch(loadUser());
    }, []);
    const openPreviewPageInNewTab = (state) => {
        console.log(state, "state");
        const data = state.data;
        console.log("data", data);
        window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
    };
    const selectedCountry = "India";
    const id = location.pathname.split("/")[3];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "3px",
            boxShadow: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            paddingRight: 0, // Adjust padding to remove space between text and dropdown icon
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: employerData.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: employerData.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };

    const [heading, setHeading] = useState(false);

    const handleTitle = () => {
        setHeading(!heading);
    };
    const calculateDaysDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = end - start;

        // Convert the difference to days
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        // Set the state with the calculated difference
        return differenceInDays;
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);

    const formatNumberWithCommas = (number) => {
        // Convert number to string
        let strNumber = number.toString();

        // Split the string into parts (crores, lakhs, thousands, and hundreds)
        let crores = strNumber.slice(0, -7);
        let lakhs = strNumber.slice(-7, -5);
        let thousands = strNumber.slice(-5, -3);
        let hundreds = strNumber.slice(-3);

        // Join the parts with commas
        return crores + (crores ? "," : "") + lakhs + (lakhs ? "," : "") + thousands + "," + hundreds;
    };

    const userType = localStorage.getItem("userType");
    const { user } = useSelector((state) => state.user);
    console.log(user, "user");
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState({
        _id: employerData ? employerData._id : "",
        organisationURL: employerData ? employerData.organisationURL : "",
        jobType: [],
    });

    const handleChange = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value });
    };

    const handleFilter = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            // If the event target is a checkbox, handle its state
            const updatedJobType = [...search.jobType];
            if (checked) {
                updatedJobType.push(name);
            } else {
                const index = updatedJobType.indexOf(name);
                if (index > -1) {
                    updatedJobType.splice(index, 1);
                }
            }

            setSearch((prevSearch) => ({
                ...prevSearch,
                jobType: updatedJobType,
            }));
        } else {
            // For other input types, handle normally
            setSearch({
                ...search,
                [name]: value,
            });
        }
    };

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/getemployerjobdata", { search });
            setJobData(data);
            setLoading(false);
        } catch {
            console.log("Server is not running...");
            setLoading(false);
        }
    };
    const getEmployerData = async () => {
        setLoading(true);

        try {
            console.log("Fetching data for employer ID:", id);
            const { data } = await axios.get(`/api/user/getSingleEmployerData/${id}`);
            console.log("Employer Data:", data);

            setEmployerData(data);
        } catch (error) {
            console.error("Error fetching employer data:", error);
        } finally {
            setLoading(false);
        }
    };

    console.log(employerData, "employerData");
    const [isSearchUpdated, setIsSearchUpdated] = useState(false);
    useEffect(() => {
        if (employerData) {
            setSearch({
                _id: employerData._id || "",
                organisationURL: employerData.organisationURL || "",
                jobType: [],
            });
        }
        setIsSearchUpdated(true)
    }, [employerData]); // Runs whenever employerData updates

    useEffect(() => {
        if (search._id) {
            
            getData();
            setIsSearchUpdated(false)

        }
    }, [search]); // Triggers API call when search is updated

    useEffect(() => {
        getEmployerData();
        window.scrollTo(0, 0);
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    // console.log(jobData,"jobdata");
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const navigate = useNavigate();
    const [value, setValue] = useState([1, 100]); // set initial value to 50

    const [showMore, setShowMore] = useState(false);

    const checkboxes = [
        { id: "Part-Time", label: "All" },
        { id: "SDE", label: "SDE" },
        { id: "DataScience", label: "Data Science" },
        { id: "UIUX", label: "UI/UX" },
        // Add more checkboxes as needed
    ];

    const visibleCheckboxes = showMore ? checkboxes : checkboxes.slice(0, 3);

    const handleViewMore = () => {
        setShowMore(!showMore);
    };
    return (
        <>
            <div className="container-fluid matched-jobs ">
                <div className="jobbox container ">
                    <div className="row RowMargin " style={{ maxWidth: "1050px" }}>
                        <div className=" col-lg-4 col-md-4 col-sm-12 mx-auto" style={{ marginTop: "18px", maxWidth: "350px" }}>
                            <div className="filterbox  pb-0" style={{ height: "83vh", boxShadow: "none" }}>
                                {employerData.organisationAvatar ? (
                                    <>
                                        <div className="mx-auto" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={employerData.organisationAvatar.url} alt="company" style={{ width: "100px", height: "auto", marginBottom: "10px" }} />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <div className="text-center">
                                    <h3 style={{ fontSize: "20px", cursor: "pointer", marginBottom: "1px" }} className="cname mt-2 ">
                                        {employerData.organisationName}
                                    </h3>
                                    {/* <p className="sub"><span className="name mr-3 " style={{ fontSize: "15px" }} >{employerData.email}</span></p> */}
                                </div>

                                <hr style={{ height: "0.05px" }} />

                                <div className="filterboxoption my-5">
                                    <div className="body">
                                        {/* Profile */}
                                        <div className="filter-category">
                                            <div className="mb-2">Profile</div>
                                            <input type="text" style={{ border: "solid 1px #B2B2B2", borderRadius: "3px", height: "40px", width: "100%", outline: "none" }} className="px-3" onChange={handleChange} name="profile" value={search.profile} />
                                        </div>

                                        <div className="checkbox-container">
                                            <div className="mt-4 category d-flex align-items-center gap-3">
                                                <input type="checkbox" id="Remote" name="Remote" onChange={handleFilter} checked={search.jobType["Remote"]} />
                                                <span>Work from home</span>
                                            </div>
                                            <div className="mt-2 category d-flex align-items-center gap-3">
                                                <input type="checkbox" id="Part-Time" name="Part-Time" onChange={handleFilter} checked={search.jobType["Part-Time"]} />
                                                <span>Part-time</span>
                                            </div>
                                            <div className="mt-2 category d-flex align-items-center gap-3">
                                                <input type="checkbox" id="Full-Time" name="Full-Time" onChange={handleFilter} checked={search.jobType["Full-Time"]} />
                                                <span>Full-time</span>
                                            </div>
                                            <div className="mt-2 category d-flex align-items-center gap-3">
                                                <input type="checkbox" id="Job" name="Job" onChange={handleFilter} checked={search.jobType["Job"]} />
                                                <span>Job</span>
                                            </div>
                                            <div className="mt-2 category d-flex align-items-center gap-3">
                                                <input type="checkbox" id="Internship" name="Internship" onChange={handleFilter} checked={search.jobType["Internship"]} />
                                                <span>Internship</span>
                                            </div>
                                        </div>
                                    </div>
                                    {employerData && employerData.organisationName ? (
                                        <>
                                            <hr style={{ height: "0.05px", marginTop: "30px" }} />
                                            <p style={{ fontWeight: "500", marginBottom: "10px" }}>About {employerData.organisationName}</p>

                                            <p>{employerData.organisationDescription && employerData.organisationDescription}</p>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                {/* <div className="filterboxoption my-5">
      <label htmlFor="exampleFormControlInput1" style={{ fontSize: "14px" }}>Job Title</label>
      <div className="row my-2">
        {visibleCheckboxes.map((checkbox) => (
          <div key={checkbox.id} className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-check">
              <input className="form-check-input mt-2" type="checkbox" id={checkbox.id} name={checkbox.id} />
              <label className="form-check-label mx-2" htmlFor={checkbox.id}>
                {checkbox.label}
              </label>
            </div>
          </div>
        ))}
        {checkboxes.length > 3 && (
          <div className="col-lg-12 col-md-12 col-sm-12">
            <button className="btn btn-link" onClick={handleViewMore}>
              {showMore ? 'View Less' : 'View More'}
            </button>
          </div>
        )}
      </div>
    </div>*/}
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="fixed-header">
                                {/*  <h3 style={{ cursor: "pointer" }} onClick={handleTitle} className="title my-5">
                  {jobData && jobData.length > 0 ? jobData.length : 0} Jobs & Internsips Found {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "}
                </h3>
                {heading ? (
                  <>
                    <p
                      style={{
                        marginTop: "-20px",
                        fontSize: "13px",
                        color: "#494949",
                        textAlign: "center",
                      }}
                    >
                      Latest opportunities posted by {employerData.organisationName}
                    </p>
                  </>
                ) : (
                  <></>
                )}*/}
                            </div>

                            {loading ? (
                                <>
                                    <div className="my-auto" style={{ textAlign: "center", marginLeft: "-10px" }}>
                                        <p style={{ color: "#4B92C8", fontWeight: "500", marginTop: "170px", fontSize: "20px", marginLeft: "17px", marginBottom: "5px" }}>Loading..</p>
                                        <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {jobData && jobData.length != 0 ? (
                                        <>
                                            <div className="content-below-header">
                                                <div className="alljobdetails pb-2">
                                                    {jobData.map((data, index) => {
                                                        return (
                                                            <>
                                                                <div className="cards my-4">
                                                                    <div className="card py-4 px-5">
                                                                        <div className="cardHeader">
                                                                            {/*NEW CODE START HERE*/}
                                                                            <div
                                                                                className="mt-3  wfm123"
                                                                                style={{
                                                                                    width: "124px",
                                                                                    borderRadius: "5px",
                                                                                    fontSize: "13px",
                                                                                    textAlign: "center",
                                                                                    fontWeight: "400",
                                                                                    padding: "3px",
                                                                                    color: "black",
                                                                                    border: "solid 1px #D1D1D1",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={arrowup}
                                                                                    alt=""
                                                                                    style={{
                                                                                        marginRight: "5px",
                                                                                        height: "17px",
                                                                                        marginTop: "-4px",
                                                                                    }}
                                                                                />
                                                                                Actively hiring
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-9">
                                                                                    <a style={{ textDecoration: "none", color: "black" }} href={`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase()}>
                                                                                        <h3
                                                                                            style={{
                                                                                                fontSize: "19px",
                                                                                                textAlign: "start",
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                            className="title pt-3"
                                                                                        >
                                                                                            {data.profile}
                                                                                        </h3>
                                                                                    </a>
                                                                                    <h4
                                                                                        style={{
                                                                                            fontSize: "15px",
                                                                                            color: "gray",
                                                                                        }}
                                                                                        className="companyname pt-3"
                                                                                    >
                                                                                        {state && employerData.organisationName ? employerData.organisationName : data.organisationName}
                                                                                    </h4>
                                                                                </div>
                                                                                <div className="col-3 text-end ">{employerData.organisationAvatar && <img src={employerData.organisationAvatar.url} alt="" className="OrgLogoAvatar" />}</div>
                                                                            </div>

                                                                            <div className="content mb-5" style={{ marginTop: "20px" }}>
                                                                                <span>
                                                                                    {data.jobType === "Remote" ? (
                                                                                        <>
                                                                                            <img src={home} alt="Engineering" style={{ width: "14px", marginTop: "-2px" }} />
                                                                                            <span className="wfm1 ml-2" style={{ fontSize: "14px", marginRight: "20px", marginTop: "-2px" }}>
                                                                                                Work From Home
                                                                                            </span>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {data.city.map((data) => (
                                                                                                <>
                                                                                                    <SlLocationPin style={{ color: "gray", fontSize: "15px", marginBottom: "3px", marginTop: "-2px" }} />
                                                                                                    <span style={{ fontSize: "15px", marginRight: "20px", marginTop: "-2px" }} className="wfm1 ml-2">
                                                                                                        {data}
                                                                                                    </span>
                                                                                                </>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </span>
                                                                                <div className=" pt-4 pb-5 " style={{ display: "flex", flexWrap: "wrap", gap: "20px 70px" }}>
                                                                                    <div className="statusBoxes">
                                                                                        <p>
                                                                                            <span>
                                                                                                <img src={circle} alt="" style={{ width: "16px" }} />
                                                                                            </span>
                                                                                            <span className="mx-2 heeading" style={{ fontWeight: "500" }}>
                                                                                                START DATE
                                                                                            </span>
                                                                                        </p>
                                                                                        <h3 className="wfm123" style={{ fontSize: "14px", maxWidth: "100px" }}>
                                                                                            {data.startDate == "Immediately (within next 30 days)" ? <>Immediately</> : <>{data.startDate}</>}
                                                                                        </h3>
                                                                                    </div>
                                                                                    {data.opportunityType !== "Job" ? (
                                                                                        <div className="statusBoxes">
                                                                                            <p>
                                                                                                <span>
                                                                                                    <img src={calendar} alt="" style={{ width: "14px" }} />
                                                                                                </span>
                                                                                                <span className="mx-2 heeading">DURATION</span>
                                                                                            </p>
                                                                                            <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                                                {data.duration} {data?.durationType || "months"}
                                                                                            </h3>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="statusBoxes">
                                                                                                <p>
                                                                                                    <span>
                                                                                                        <img src={money} alt="" style={{ width: "14px" }} />
                                                                                                    </span>
                                                                                                    <span className="mx-2 heeading">CTC (ANNUAL)</span>
                                                                                                </p>
                                                                                                {data.salary == 0 ? (
                                                                                                    <>
                                                                                                        <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                                                            Unpaid
                                                                                                        </h3>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                                                            ₹ {formatNumberWithCommas(data.salary)}
                                                                                                            {data.salaryMax ? <> - {formatNumberWithCommas(data.salaryMax)}</> : <></>}
                                                                                                        </h3>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    {data.opportunityType === "Job" ? (
                                                                                        <>
                                                                                            <div className="statusBoxes">
                                                                                                <p>
                                                                                                    <span>
                                                                                                        <BiBriefcaseAlt style={{ color: "#9b9b9b", fontSize: "15px" }} />
                                                                                                    </span>
                                                                                                    <span className="mx-2 heeading">EXPERIENCE</span>
                                                                                                </p>
                                                                                                <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                                                    {data.minExperience}
                                                                                                    {data.maxExperience ? -data.maxExperience : <></>} years
                                                                                                </h3>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="statusBoxes">
                                                                                                <p>
                                                                                                    <span>
                                                                                                        <img src={money} alt="" style={{ width: "14px" }} />
                                                                                                    </span>
                                                                                                    <span className="mx-2 heeading">STIPEND</span>
                                                                                                </p>
                                                                                                {data.salary == 0 ? (
                                                                                                    <>
                                                                                                        <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                                                            Unpaid
                                                                                                        </h3>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                                                                            {" "}
                                                                                                            ₹ {formatNumberWithCommas(data.salary)} /month
                                                                                                        </h3>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    <div className="pr-1 pb-4">
                                                                                        <span>
                                                                                            <span>
                                                                                                {calculateDaysDifference(data.datePosted).toFixed(0) < 6 ? (
                                                                                                    <>
                                                                                                        {calculateDaysDifference(data.datePosted).toFixed(0) == 0 ? (
                                                                                                            <span className=" post1" style={{ backgroundColor: "#E1FFE1", color: "green" }}>
                                                                                                                <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted just now
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span className=" post1" style={{ backgroundColor: "#E1FFE1", color: "green" }}>
                                                                                                                <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted {calculateDaysDifference(data.datePosted).toFixed(0)} days ago
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {calculateDaysDifference(data.datePosted).toFixed(0) > 5 && calculateDaysDifference(data.datePosted).toFixed(0) < 11 ? (
                                                                                                            <>
                                                                                                                <span className=" post1 " style={{ backgroundColor: "#E2F8FF", color: "#0D8CB8" }}>
                                                                                                                    <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted {calculateDaysDifference(data.datePosted).toFixed(0)} days ago
                                                                                                                </span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <span className=" post1 ">
                                                                                                                    <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted {calculateDaysDifference(data.datePosted).toFixed(0)} days ago
                                                                                                                </span>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </span>{" "}
                                                                                            <span className=" post1" style={{ marginLeft: "10px" }}>
                                                                                                {data.opportunityType}
                                                                                            </span>
                                                                                            {data.isPartTimeAllowed == "Yes" ? (
                                                                                                <span className=" post1" style={{ marginLeft: "10px" }}>
                                                                                                    Part time
                                                                                                </span>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </span>

                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                                {/*NEW CODE END HERE*/}
                                                                            </div>
                                                                            <hr
                                                                                className=""
                                                                                style={{
                                                                                    height: "0.1px",
                                                                                    color: "#C4C4C4",
                                                                                    marginTop: "-60px",
                                                                                }}
                                                                            ></hr>
                                                                            <div className="cardbutton ">
                                                                                <div
                                                                                    className="ml-auto text-end"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexWrap: "wrap",
                                                                                    }}
                                                                                >
                                                                                    <button onClick={() => openPreviewPageInNewTab({ data })} className=" btnview1 ml-auto">
                                                                                        {" "}
                                                                                        View Details
                                                                                    </button>
                                                                                    {user ? (
                                                                                        userType !== "Employer" &&
                                                                                        userType !== null &&
                                                                                        (data.userApplied.includes(user ? user._id : "") ? (
                                                                                            <>
                                                                                                <p
                                                                                                    style={{
                                                                                                        color: "green",
                                                                                                        fontWeight: "400",
                                                                                                        marginBottom: "-2px",
                                                                                                        marginLeft: "10px",
                                                                                                        marginRight: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    <MdOutlineCheckCircle
                                                                                                        style={{
                                                                                                            marginRight: "3px",
                                                                                                            fontSize: "18px",
                                                                                                            marginTop: "-2px",
                                                                                                        }}
                                                                                                    />
                                                                                                    Applied
                                                                                                </p>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {/* <button
                                                    onClick={() => navigate("/coverletterpage", { state: data })}
                                                    className="btn btnapply"
                                                    style={{
                                                      width: "100px",
                                                      marginLeft: "20px",
                                                    }}
                                                  >
                                                    Apply Now
                                                  </button> */}
                                                                                                <button
                                                                                                    onClick={() => {
                                                                                                        const { phone, city, resume } = user;

                                                                                                        if (!phone && !city && !resume) {
                                                                                                            navigate("/addResume");
                                                                                                        } else if ((phone || city) && !resume) {
                                                                                                            navigate("/addResume");
                                                                                                        } else {
                                                                                                            // navigate("/coverLetterPage", { state: data });
                                                                                                            const jobId = `${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
                                                                                                            const encodedJobId = encodeURIComponent(jobId);
                                                                                                            navigate(`/coverletterpage/${encodedJobId}`, { state: data });
                                                                                                        }
                                                                                                    }}
                                                                                                    className="btn btnapply"
                                                                                                    style={{
                                                                                                        width: "100px",
                                                                                                        marginLeft: "20px",
                                                                                                    }}
                                                                                                >
                                                                                                    Apply Now
                                                                                                </button>
                                                                                            </>
                                                                                        ))
                                                                                    ) : (
                                                                                        <>
                                                                                            <div
                                                                                                style={{
                                                                                                    textAlign: "center",
                                                                                                }}
                                                                                            >
                                                                                                <button
                                                                                                    onClick={() => navigate("/login")}
                                                                                                    className="btn btnapply"
                                                                                                    style={{
                                                                                                        marginLeft: "0px",
                                                                                                    }}
                                                                                                >
                                                                                                    Apply Now
                                                                                                </button>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </div>

                                            {/* <div className="pagination" style={{ marginTop: "-20px" }}>
                        <p onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <IoIosArrowBack className="paginationBtn" style={{ marginTop: "6px" }} />
                        </p>
                        <span style={{ fontSize: "16px" }}>
                          {currentPage} / {totalPages}
                        </span>
                        <p onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                          <IoIosArrowForward className="paginationBtn" style={{ marginTop: "6px" }} />
                        </p>
                      </div>*/}
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-center mt-5">
                                                <i class="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: "200px" }}></i>
                                                <h1>Job not Found</h1>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default CompanyDetail;
