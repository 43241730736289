import React, { useState, useEffect, useRef } from "react";
import "./css/Support.scss";
import { MdOutlineArrowBack } from "react-icons/md";
import supportLogo from "../../assets/images/support.png";
import mail from "../../assets/images/mail.png";
import chat from "../../assets/images/Vector.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { logEvent } from "../../utils/Analytics";
function Support() {
    const { user } = useSelector((state) => state.user);
    const closeMailUs = useRef(null);
    const closeTextUs = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [mailUs, setMailUs] = useState({
        email: "",
        message: "",
        senderId: user && user._id,
    });
    const [textUs, setTextUs] = useState({
        email: "",
        message: "",
    });

    const navigate = useNavigate();
    const handleInputMailUs = (e) => {
        setMailUs({ ...mailUs, [e.target.name]: e.target.value });
    };

    const handleInputTextUs = (e) => {
        setTextUs({ ...textUs, [e.target.name]: e.target.value });
    };

    const handleSubmitMailUs = async () => {
        setLoading(true);
        if (mailUs.email === "") {
            toast.error("Enter email");
            setLoading(false);
        } else if (mailUs.message === "") {
            toast.error("Enter message");
            setLoading(false);
        } else {
            try {
                const { data } = await axios.post("/api/send-email", {
                    email: mailUs.email,
                    message: mailUs.message,
                    senderId: user && user._id,
                });
                toast.success(data.message);
                setMailUs({ ...mailUs, email: "", message: "" });
                setLoading(false);
                closeMailUs.current.click();
            } catch (err) {
                console.error("Error fetching data: " + err);
                toast.error("Internal server error");
                setLoading(false);
            }
        }
    };

    const handleSubmitTextUs = async () => {
        setLoading2(true);
        if (textUs.email === "") {
            toast.error("Enter email");
            setLoading2(false);
        } else if (textUs.message === "") {
            toast.error("Enter message");
            setLoading2(false);
        } else {
            try {
                const { data } = await axios.post("/api/send-email", {
                    email: textUs.email,
                    message: textUs.message,
                    senderId: user && user._id,
                });
                toast.success(data.message);
                setTextUs({ ...textUs, email: "", message: "" });
                setLoading2(false);
                closeTextUs.current.click();
            } catch (err) {
                console.error("Error fetching data: " + err);
                toast.error("Internal server error");
                setLoading2(false);
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
        
    const handleAddEvent = (str) => {
        console.log(str)
        logEvent("Button", `Clicked ${str} button`);
    };
    return (
        <>
            <div className="py-5 mx-auto" style={{ backgroundColor: "white",maxWidth: "1200px",padding:"20px" }}>
                {/*<MdOutlineArrowBack className="mx-5" style={{fontSize: "30px", cursor: "pointer"}} onClick={() => navigate('/employerApplication')} />*/}
                <h1 className="text-center " style={{ fontSize: "30px", fontWeight: "500", color: "#4F4F4F", marginBottom: "10px" }}>
                    Help & Support
                </h1>

                <div className="whiteScreen pb-5 mb-5" style={{ backgroundColor: "rgb(240, 247, 252)" }}>
                        <div className="firstcol" style={{ minWidth: "250px" }}>
                            <p className="supportTitle" style={{ fontWeight: "500", color: "#4F4F4F", marginTop: "80px" }}>Need some help?</p>
                            <p className="supportSubTitle" style={{ fontWeight: "500", color: "#4F4F4F", marginTop: "10px" }}>Want to get in touch? We’d love to get in touch </p>
                            <p className="supportSubTitle" style={{ fontWeight: "500", color: "#4F4F4F", marginTop: "-14px" }}>with you, Here’s how you can search us</p>
                        </div>
                        <div className="secondcol">
                            <img className="my-5" src={supportLogo} alt=".." style={{ height: "200px", width: "auto" }} />
                        </div>
                </div>

                <div className="row" >
                    <div className="col-12 col-md-6" style={{ margin: "5px 0px" }}>
                        <div className="supportCard mx-auto">
                            <img src={mail} alt=".." style={{ marginTop: "10px" }} />
                            <p className="cardTitle" style={{ fontWeight: "500", color: "#4F4F4F" }}>Talk to us</p>
                            <p className="cardText" style={{ fontWeight: "500", color: "#4F4F4F" }}>
                                Having a difficulty with something e-mail us
                            </p>
                            <button className="supportBtn" data-bs-toggle="modal" data-bs-target="#mailUs" onClick={() => handleAddEvent("Mail us")}>
                                Mail Us
                            </button>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="supportCard mx-auto" style={{ margin: "5px 0px" }}>
                            <img src={chat} alt=".." style={{ marginTop: "10px", width: "27px" }} />
                            <p className="cardTitle" style={{ fontWeight: "500", color: "#4F4F4F"}}>Text Customer Support</p>
                            <p className="cardText" style={{ fontWeight: "500", color: "#4F4F4F" }}>
                                Sometimes you need a little help from your friends. Or our support rep. Don't worry.. we're here for you.
                            </p>
                            <button className="supportBtn" data-bs-toggle="modal" data-bs-target="#textUs" onClick={() => handleAddEvent("Text us")}>
                                Text Us
                            </button>
                        </div>
                    </div>
                </div>

                <p className="supportNote">
                    <b className="supportNote3">Note:</b> <span className="supportNote4">Rest assured, our team is dedicated to promptly addressing your inquiry. We value your communication and understand its timeliness. Currently, our dedicated team is reviewing your request, and we will respond shortly. If you have any further questions or concerns, please don't hesitate to contact us. Thank you for your patience, and we look forward to assisting you soon!</span>
                </p>
                <h4 className="supportNotenew">Address:</h4>
                <div className="supportNotenew2">4/2, E-Extn, Shyam Vihar Phase 1, Najafgarh, Delhi - 110043, India</div>
                <h5 className="supportNotenew">Contact Number:</h5>
                <div className="supportNotenew2">India, Phone: (+91) 7428477468</div>
            </div>

            {/* Model 1: Mail Us */}
            <div class="modal fade" id="mailUs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                    <div class="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                        <div class="modal-body my-4" style={{ borderRadius: "20px" }}>
                            <div style={{ width: "30px", marginLeft: "auto", marginTop: "9px" }}>
                                <button type="button" class="btn-close" ref={closeMailUs} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div>
                                <p style={{ fontSize: "36px", fontWeight: "500", color: "#444444" }}>
                                    {" "}
                                    <img src={mail} alt=".." style={{ width: "30px", height: "26px", marginRight: "8px", marginTop: "-5px" }} /> Talk to us
                                </p>
                            </div>

                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "-5px" }}>Having a difficulty with something e-mail us</p>
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "40px" }}>Enter Your Email</p>
                            <input className="" type="text" style={{ width: "100%", borderRadius: "10px", fontSize: "18px", marginTop: "5px", padding: "20px", border: "solid 1px #959595", outline: "none" }} name="email" value={mailUs.email} onChange={handleInputMailUs} />
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "30px" }}>Message</p>
                            <textarea rows={5} style={{ width: "100%", borderRadius: "10px", padding: "10px", border: "solid 1px #959595", outline: "none" }} name="message" value={mailUs.message} onChange={handleInputMailUs} />

                            <div className="text-end">
                                {loading ? (
                                    <button className="sendAssignmentBtn mt-4" style={{ width: "150px" }}>
                                        <Spinner />
                                    </button>
                                ) : (
                                    <button className="sendAssignmentBtn mt-4" onClick={ () => {handleSubmitMailUs();handleAddEvent("Send mail")}}>
                                        Send Email
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Model 2: Text Us */}
            <div class="modal fade" id="textUs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered" style={{ borderRadius: "20px" }}>
                    <div class="modal-content PopupBox" style={{ borderRadius: "20px" }}>
                        <div class="modal-body my-4" style={{ borderRadius: "20px" }}>
                            <div style={{ width: "30px", marginLeft: "auto", marginTop: "9px" }}>
                                <button type="button" class="btn-close" ref={closeTextUs} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div>
                                <p style={{ fontSize: "36px", fontWeight: "500", color: "#444444" }}>
                                    {" "}
                                    <img src={chat} alt=".." style={{ width: "28px", height: "28px", marginRight: "8px", marginTop: "-5px" }} />
                                    Text Customer Support
                                </p>
                            </div>

                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "-5px" }}>Sometimes you need a little help from your friends. Or our support rep. Don't worry.. we're here for you.</p>
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "40px" }}>Enter Your Email</p>
                            <input className="" type="text" style={{ width: "100%", borderRadius: "10px", fontSize: "18px", marginTop: "5px", padding: "20px", border: "solid 1px #959595", outline: "none" }} name="email" value={textUs.email} onChange={handleInputTextUs} />
                            <p style={{ fontSize: "22px", fontWeight: "500", marginTop: "30px" }}>Message</p>
                            <textarea rows={5} style={{ width: "100%", borderRadius: "10px", padding: "10px", border: "solid 1px #959595", outline: "none" }} name="message" value={textUs.message} onChange={handleInputTextUs} />

                            <div className="text-end">
                                {loading2 ? (
                                    <button className="sendAssignmentBtn mt-4" style={{ width: "150px" }}>
                                        <Spinner />
                                    </button>
                                ) : (
                                    <button className="sendAssignmentBtn mt-4" onClick={ () => {handleSubmitTextUs();handleAddEvent("Text Us")}}>
                                        Text Us
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default Support;
