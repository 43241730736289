import { Button, Grid, MenuItem, Pagination as MuiPagination, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import filterLogo from "../../assets/images/Group.png";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./css/MatchedJobs.scss";
import Select from "react-select";
import { Degree } from "../data";
import { AVAILABILITY } from "../data";
import { IoChatbubbleOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { IoChatboxOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadUser } from "../../actions/userActions";
import Popup from "reactjs-popup";
import { RxCross1 } from "react-icons/rx";
import Pagination from "../Employer/Pagination";
import { logEvent } from "../../utils/Analytics";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const truncateWithTooltip = (text, length) => {
    if (!text) return "Not available";
    return text.length > length ? (
        <Tooltip title={<div className="custom-tooltip">{text}</div>}>
            <span>{text.slice(0, length)}...</span>
        </Tooltip>
    ) : (
        text
    );
};
function CandidateDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userType = localStorage.getItem("userType");
    const { user } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState({});

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    const [once, setOnce] = useState(false);

    const clearSearch = async () => {
        setSearch({
            ...search,
            profile: "",
            availability: "",
            jobType: [],
            skills: [],
        });
        setOnce(true);
    };
    const handleBuyConnects = () => {
        navigate("/employerbuyconnects");
        setShowModal(false);
    };

    useEffect(() => {
        if (once) {
            getData();
            setOnce(false);
        }
    });

    /*  useEffect(() => {
   if(userType == "Employer"){
    navigate("/candidate-details-emp");
   }
  });  */

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            maxWidth: "275px",
            width: "20vw",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: state.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };

    const customStylesOne = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            minWidth: "200px",
            maxWidth: "275px",
            width: "100%",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: state.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };

    const [profiles, setProfiles] = useState([]);
    const [pdskills, setPdskills] = useState([]);
    const [searchTerm2, setSearchTerm2] = useState("");

    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isTyping1, setIsTyping1] = useState(false);
    // useEffect(() => {
    //   async function getAllProfiles() {
    //     const response = await axios.get("/api/data/profiles");
    //     const data = await response.data;
    //     if (data) setProfiles(data);
    //   }
    //   // async function getAllSkills() {
    //   //   const response = await axios.get("/api/data/skills");
    //   //   const data = await response.data;
    //   //   if (data) setPdskills(data);
    //   // }

    //   // getAllSkills();
    //   getAllProfiles();
    // }, []);
    useEffect(() => {
        async function getAllProfiles() {
            const response = await axios.get("/api/data/profiles");
            const data = await response.data;
            if (data) {
                setProfiles(data);
            }
        }

        getAllProfiles();
    }, []);
    const filterOptions = (inputValue) => {
        return profiles?.filter((profile) => profile.toLowerCase().includes(inputValue.toLowerCase()));
    };
    useEffect(() => {
        async function fetchAllSkills() {
            if (searchTerm2.trim()) {
                try {
                    const response = await axios.get(`/api/data/skills`, {
                        params: { keyword: searchTerm2 },
                    });
                    if (Array.isArray(response.data)) {
                        setPdskills(response.data);
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching skills:", error);
                }
            } else {
                setPdskills([]);
            }
        }
        fetchAllSkills();
    }, [searchTerm2]);
    const [isTyping, setIsTyping] = useState(false);
    const handleInputChange2 = (newValue) => {
        setSearchTerm2(newValue);
        setIsTyping(newValue.length > 0);
        // const matchingSkills = pdskills.filter(skill => skill.toLowerCase().includes(newValue.toLowerCase()));
        // setIsTyping(matchingSkills.length > 0);
    };
    const itemsPerPage = 15; // Set the number of items to display per page
    const [pageCount, setpageCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [teamData, setTeamData] = useState("");
    const [search, setSearch] = useState({
        jobType: [],
        skills: [],
    });
    const [loading, setLoading] = useState(false);

    const handleFilter = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            // If the event target is a checkbox, handle its state
            const updatedJobType = [...search.jobType];
            if (checked) {
                updatedJobType.push(name);
            } else {
                const index = updatedJobType.indexOf(name);
                if (index > -1) {
                    updatedJobType.splice(index, 1);
                }
            }

            setSearch((prevSearch) => ({
                ...prevSearch,
                jobType: updatedJobType,
            }));
        } else {
            // For other input types, handle normally
            setSearch({
                ...search,
                [name]: value,
            });
        }
    };

    const handleChange = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value });
    };

    const handleSearch = async () => {
        setCurrentPage(1);
        await getData();
    };

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post(`/api/user/getuserdata?page=${currentPage}&limit=${itemsPerPage}`, { search });

            setTeamData(data.data);
            setpageCount(data.pagination?.totalPages);
            setLoading(false);
        } catch {
            console.log("Server is not running...");
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, [currentPage]);

    teamData && teamData?.sort((a, b) => new Date(b.dataAdded) - new Date(a.dataAdded));
    // Calculate the total number of pages
    // const pageCount = Math.ceil(teamData.length / itemsPerPage);

    // Get the current page data
    const currentData = teamData;
    console.log(teamData, "teamData");
    // Handle page change
    const handlePageChange = (value) => {
        setCurrentPage(value);
    };

    const [loadingChat, setLoadingChat] = useState(false);

    // HANDLE USERS CHAT:
    const ChatToUser = async (data) => {
        setLoadingChat(true);
        handleAddEvent("Chat");
        try {
            const response = await axios.post("/api/user/createRoom", {
                userId: data._id,
                employerId: user && user._id,
                jobId: "6613d85f0ef12e507f453a46",
            });
            if (response.data.success) {
                navigate("/messageEmployer", { state: response.data.data });
                setLoadingChat(false);
            }
        } catch (error) {
            console.error("Error occurred while sending request:", error);
            setLoadingChat(false);
        }
    };
    const [showEmail, setShowEmail] = useState(Array(currentData.length).fill(false));
    const [showPhone, setShowPhone] = useState(Array(currentData.length).fill(false));

    const handleShowAll = async (index) => {
        console.log("index---", index);
        if (user && (!user.organisationName || !user.organisationDescription)) {
            navigate("/myProfile/company");
            return;
        }
        handleAddEvent("Show email and phone number");
        try {
            const response = await axios.post("/api/employer/deductConnectCreditofemployer", { employerId: user._id });
            console.log("Response:", response.data);
            if (response.data.message === "Please Buy Connects.") {
                // toast.error('Please buy connects.');
                setShowModal(true);
                return;
            }
        } catch (error) {
            toast.error(error.message);
        }
        const newShowEmail = [...showEmail];
        newShowEmail[index] = true;
        setShowEmail(newShowEmail);

        const newShowPhone = [...showPhone];
        newShowPhone[index] = true;
        setShowPhone(newShowPhone);

        try {
            await axios.post("/api/employer/saveUserEmailInEmployer", {
                employerId: user._id,
                userEmail: currentData[index].email,
            });
        } catch (error) {
            console.log("error:", error);
        }
    };
    const [userEmails, setUserEmails] = useState([]);

    useEffect(() => {
        const fetchUserEmailsByEmployerId = async () => {
            try {
                const response = await axios.get(`/api/employer/getUserEmailsFromEmployer/${user._id}`);
                setUserEmails(response.data.userEmails);
            } catch (error) {
                console.error("Error fetching user emails by employerId:", error);
            }
        };
        if (user && user.role === "Employer") {
            fetchUserEmailsByEmployerId();
        }
    }, [user]);
    console.log(userEmails, "userEmails");

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`);
    };
    const [activeTooltip, setActiveTooltip] = useState(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Add useEffect for mobile detection
    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Handle tooltip interactions based on device
    const handleTooltipInteraction = (id) => {
        if (isMobile) {
            setTooltipOpen((prev) => ({ ...prev, [id]: !prev[id] }));
        }
    };

    // Handle mouse enter for desktop
    const handleMouseEnter = (id) => {
        if (!isMobile) {
            setTooltipOpen((prev) => ({ ...prev, [id]: true }));
        }
    };

    // Handle mouse leave for desktop
    const handleMouseLeave = (id) => {
        if (!isMobile) {
            setTooltipOpen((prev) => ({ ...prev, [id]: false }));
        }
    };
    const TooltipContent = React.memo(({ children }) => <Typography sx={{ fontSize: "16px" }}>{children}</Typography>);

    return (
        <>
            <div className="py-4" style={{ backgroundColor: "rgba(224, 220, 243, 0.212)" }}>
                <Grid item container md={12} xs={12} lg={12} justifyContent="center" alignItems="center" mt={5} mb={5}>
                    <h2
                        className="borderremove"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                        }}
                    >
                        Applicant Details
                    </h2>
                </Grid>

                <div
                    className="borderremovePc"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        height: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "5%",
                        borderRadius: "10px",
                        paddingLeft: "30px",
                        maxWidth: "1200px",
                        width: "90%",
                    }}
                >
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container xs={12} md={12} justifyContent="center" mt={2}>
                            {/* <h1>Filters</h1>*/}
                            <img src={filterLogo} alt="Account" style={{ width: "25px", height: "25px" }} /> <h2 style={{ marginTop: "2px" }}>Filters</h2>
                        </Grid>
                        <Grid item container xs={6} md={3} justifyContent="center">
                            <Grid item container xs={12} md={12} justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                    Expertise In
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} md={12}>
                                {/* <Form.Control onChange={handleChange} name="profile" value={search.profile} placeholder="Search profile" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} />*/}
                                {/* <Select
                 styles={customStyles}
                  options={profiles?.map((data) => ({ value: data, label: data }))}
                  placeholder={<div style={{ fontSize: '15px' }}>Search profile</div>}
                  onChange={(selectedOption) => {
                    setSearch({ ...search, profile: selectedOption?.value })
                  }}
                  value={search.profile ? { value: search.profile, label: search.profile } : null}
                /> */}
                                <Select
                                    options={filteredProfiles?.map((data) => ({
                                        value: data,
                                        label: data,
                                    }))}
                                    placeholder={<div style={{ fontSize: "15px" }}>Search profile</div>}
                                    onChange={(selectedOption) => {
                                        setSearch({ ...search, profile: selectedOption?.value });
                                    }}
                                    value={search.profile ? { value: search.profile, label: search.profile } : null}
                                    onInputChange={(inputValue) => {
                                        setInputValue(inputValue);
                                        setIsTyping1(inputValue.trim() !== "");
                                        if (inputValue.trim() !== "") {
                                            setFilteredProfiles(filterOptions(inputValue));
                                        } else {
                                            setFilteredProfiles([]);
                                        }
                                        // const filtered = inputValue.trim() !== '' ? filterOptions(inputValue) : [];
                                        // setFilteredProfiles(filtered);
                                        // setIsTyping1(inputValue.trim() !== '' && filtered.length > 0);
                                    }}
                                    inputValue={inputValue}
                                    menuIsOpen={isTyping1}
                                    styles={customStyles}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={6} md={3} justifyContent="center">
                            <Grid item container xs={12} md={12} justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                    Availability
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} md={12}>
                                {/* <Form.Control onChange={handleChange} name="degree" value={search.degree} placeholder="Search degree" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                                <Select
                                    styles={customStyles}
                                    options={AVAILABILITY?.map((data) => ({
                                        value: data,
                                        label: data,
                                    }))}
                                    placeholder={<div style={{ fontSize: "15px" }}>Search availability</div>}
                                    onChange={(selectedOption) => {
                                        setSearch({
                                            ...search,
                                            availability: selectedOption?.value,
                                        });
                                    }}
                                    value={
                                        search.availability
                                            ? {
                                                  value: search.availability,
                                                  label: search.availability,
                                              }
                                            : null
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} md={3} container justifyContent="center">
                            <Grid item container xs={12} md={12} justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                    Skills
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} md={12}>
                                {/*<Form.Control onChange={handleChange} name="skills" value={search.skills} placeholder="Search skills" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                                <Select
                                    styles={customStyles}
                                    options={pdskills?.map((data) => ({
                                        value: data,
                                        label: data,
                                    }))}
                                    placeholder={<div style={{ fontSize: "15px" }}>Search skills</div>}
                                    onChange={(selectedOptions) => {
                                        setSearch({
                                            ...search,
                                            skills: selectedOptions.map((option) => option.value),
                                        });
                                    }}
                                    value={
                                        search.skills
                                            ? search.skills.map((skill) => ({
                                                  value: skill,
                                                  label: skill,
                                              }))
                                            : null
                                    }
                                    isMulti
                                    onInputChange={handleInputChange2}
                                    menuIsOpen={isTyping}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={3} container justifyContent="center">
                            <Grid item xs={12} md={12} container justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000", paddingLeft: "25px" }}>
                                    Profile
                                </Typography>
                            </Grid>
                            <div className="row my-2 profilemobilerespon">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-check">
                                        <input class="form-check-input mt-2" type="checkbox" id="Experience" name="Experience" onChange={handleFilter} checked={search.jobType["Experience"]} />
                                        <label class="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Experience">
                                            Experience
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-check">
                                        <input class="form-check-input mt-2" type="checkbox" id="Fresher" name="Fresher" onChange={handleFilter} checked={search.jobType["Fresher"]} />
                                        <label class="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Fresher">
                                            Fresher
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item container xs={12} md={12} justifyContent="end" sx={{ paddingRight: "3%", paddingBottom: "2%", paddingTop: "1%" }}>
                            <Button
                                onClick={() => {
                                    clearSearch();
                                    handleAddEvent("Clear filter");
                                }}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    textDecoration: "none",
                                    background: "none",
                                    color: "#4B92C8",
                                    "&:hover": { background: "#4B92C8", color: "white" },
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={() => {
                                    handleSearch();
                                    handleAddEvent("Apply filter");
                                }}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    marginLeft: "20px",
                                    textDecoration: "none",
                                    background: "#4B92C8",
                                    "&:hover": { background: "none", color: "#4B92C8" },
                                }}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {/*MOBILE FILTER BOX*/}

                <div
                    className="borderremoveMob"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        height: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "5%",
                        borderRadius: "10px",
                        paddingLeft: "30px",
                        maxWidth: "1200px",
                        width: "90%",
                    }}
                >
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container xs={12} md={12} justifyContent="center" mt={2}>
                            {/* <h1>Filters</h1>*/}
                            <img src={filterLogo} alt="Account" style={{ width: "25px", height: "25px" }} /> <h2 style={{ marginTop: "2px" }}>Filters</h2>
                        </Grid>
                        <div className="row my-4 px-4" style={{ justifyContent: "center" }}>
                            <div className="col-3 my-4" style={{ minWidth: "270px" }}>
                                <Grid item container xs={12} md={12} justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Expertise In
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    {/* <Form.Control onChange={handleChange} name="profile" value={search.profile} placeholder="Search profile" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} />*/}
                                    <Select
                                        styles={customStylesOne}
                                        options={profiles?.map((data) => ({
                                            value: data,
                                            label: data,
                                        }))}
                                        placeholder={<div style={{ fontSize: "15px" }}>Search profile</div>}
                                        onChange={(selectedOption) => {
                                            setSearch({ ...search, profile: selectedOption?.value });
                                        }}
                                        value={search.profile ? { value: search.profile, label: search.profile } : null}
                                    />
                                </Grid>
                            </div>

                            <div className="col-3 my-4" style={{ minWidth: "270px" }}>
                                <Grid item container xs={12} md={12} justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Availability
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    {/* <Form.Control onChange={handleChange} name="degree" value={search.degree} placeholder="Search degree" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                                    <Select
                                        styles={customStylesOne}
                                        options={AVAILABILITY?.map((data) => ({
                                            value: data,
                                            label: data,
                                        }))}
                                        placeholder={<div style={{ fontSize: "15px" }}>Search availability</div>}
                                        onChange={(selectedOption) => {
                                            setSearch({
                                                ...search,
                                                availability: selectedOption?.value,
                                            });
                                        }}
                                        value={
                                            search.availability
                                                ? {
                                                      value: search.availability,
                                                      label: search.availability,
                                                  }
                                                : null
                                        }
                                    />
                                </Grid>
                            </div>

                            <div className="col-3 my-4" style={{ minWidth: "270px" }}>
                                <Grid item container xs={12} md={12} justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Skills
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    {/*<Form.Control onChange={handleChange} name="skills" value={search.skills} placeholder="Search skills" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                                    <Select
                                        styles={customStylesOne}
                                        options={pdskills?.map((data) => ({
                                            value: data,
                                            label: data,
                                        }))}
                                        placeholder={<div style={{ fontSize: "15px" }}>Search skills</div>}
                                        onChange={(selectedOptions) => {
                                            setSearch({
                                                ...search,
                                                skills: selectedOptions.map((option) => option.value),
                                            });
                                        }}
                                        value={
                                            search.skills
                                                ? search.skills.map((skill) => ({
                                                      value: skill,
                                                      label: skill,
                                                  }))
                                                : null
                                        }
                                        isMulti
                                    />
                                </Grid>
                            </div>
                            <div className="col-3  pl-5 my-4" style={{ minWidth: "270px" }}>
                                <Grid item xs={12} md={12} container justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Profile
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} container justifyContent="start">
                                    <div className="row my-2">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-check">
                                                <input class="form-check-input mt-2" type="checkbox" id="Experience" name="Experience" onChange={handleFilter} checked={search.jobType["Experience"]} />
                                                <label class="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Experience">
                                                    Experience
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-check">
                                                <input class="form-check-input mt-2" type="checkbox" id="Fresher" name="Fresher" onChange={handleFilter} checked={search.jobType["Fresher"]} />
                                                <label class="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Fresher">
                                                    Fresher
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                        <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            justifyContent="end"
                            sx={{
                                paddingRight: "3%",
                                paddingBottom: "2%",
                                paddingTop: "1%",
                                marginBottom: "10px",
                            }}
                        >
                            <Button
                                onClick={clearSearch}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    textDecoration: "none",
                                    background: "none",
                                    color: "#4B92C8",
                                    "&:hover": { background: "#4B92C8", color: "white" },
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={handleSearch}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    marginLeft: "20px",
                                    textDecoration: "none",
                                    background: "#4B92C8",
                                    "&:hover": { background: "none", color: "#4B92C8" },
                                }}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <div className="borderremove" style={{ backgroundColor: "white", borderRadius: "10px", marginLeft: "auto", marginRight: "auto", marginBottom: "50px", maxWidth: "1200px", width: "90%", overflow: "auto" }}>
                    <div style={{ width: "100%", minWidth: "1200px" }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ borderRadius: "10px 10px  0  0 ", fontSize: { md: "16px", sm: "14px", xs: "12px" }, fontWeight: "500", color: "#FFF", paddingTop: "1%", background: "#4B92C8", paddingLeft: "3%" }}>
                            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
                                <p>Name</p>
                            </Grid>
                            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
                                <p>Profile</p>
                            </Grid>
                            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
                                <p>Availability</p>
                            </Grid>
                            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
                                <p>Skills</p>
                            </Grid>
                            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
                                <p>Email</p>
                            </Grid>
                            <Grid item xs={2} md={2} container justifyContent="start" alignItems="start">
                                <p>Contact Number</p>
                            </Grid>
                        </Grid>

                        {/* Data rows */}
                        {loading ? (
                            <>
                                <Grid item xs={12} md={12} container justifyContent="start" alignItems="center" style={{ paddingLeft: "5%" }}>
                                    <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px" }}>
                                        <ScaleLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                    </div>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <div style={{ marginTop: "10px" }}></div>
                                {currentData && currentData.length > 0 ? (
                                    <>
                                        {/* Single tooltip state */}
                                        {(() => {
                                            return currentData.map((item, index) => (
                                                <div key={item._id || index}>
                                                    <Grid container justifyContent="center" alignItems="center" direction="row" sx={{ fontSize: { sm: "13px", xs: "12px" }, fontWeight: "400", color: "#000", paddingLeft: "3%" }}>
                                                        {/* Name Column */}
                                                        <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            <Tooltip
                                                                open={activeTooltip === `name_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.name}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `name_${item._id}` ? null : `name_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {truncateWithTooltip(item.name, 17)}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        {/* Personal Details Column */}
                                                        <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            <Tooltip
                                                                open={activeTooltip === `profile_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.resume.PersonalDetails ? item.resume.PersonalDetails.profile : "Not available"}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `profile_${item._id}` ? null : `profile_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {item.resume.PersonalDetails ? truncateWithTooltip(item.resume.PersonalDetails.profile, 20) : "Not available"}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        {/* Availability Column */}
                                                        <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            <Tooltip
                                                                open={activeTooltip === `availability_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.availability || ""}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `availability_${item._id}` ? null : `availability_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {item.availability ? truncateWithTooltip(item.availability, 20) : ""}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        {/* Skills Column */}
                                                        <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            <Tooltip
                                                                open={activeTooltip === `skills_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.resume.Skills && item.resume.Skills.length > 0 ? item.resume.Skills.map((data) => data.name).join(", ") : "Not available"}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `skills_${item._id}` ? null : `skills_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {item.resume.Skills && item.resume.Skills.length > 0 ? truncateWithTooltip(item.resume.Skills.map((data) => data.name).join(", "), 20) : "Not available"}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        {/* Keep existing email and phone columns */}
                                                        <Grid item xs={2} md={2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            {user && user.role === "Employer" ? (
                                                                userEmails && userEmails.length > 0 && userEmails.includes(item.email) ? (
                                                                    <ClickAwayListener onClickAway={() => setTooltipOpen((prev) => ({ ...prev, [item._id]: false }))}>
                                                                        <div onClick={() => handleTooltipInteraction(item._id)} onMouseEnter={() => handleMouseEnter(item._id)} onMouseLeave={() => handleMouseLeave(item._id)}>
                                                                            <Tooltip open={tooltipOpen[item._id] || false} title={<Typography sx={{ fontSize: "16px" }}>{item.email}</Typography>} arrow>
                                                                                <p style={{ marginBottom: "0px", cursor: "pointer" }}>{item.email ? <>{item.email.length > 20 ? item.email.slice(0, 15) + "..." : item.email}</> : <>Not available</>}</p>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </ClickAwayListener>
                                                                ) : (
                                                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        {showEmail[index] ? (
                                                                            <ClickAwayListener onClickAway={() => setTooltipOpen((prev) => ({ ...prev, [item._id]: false }))}>
                                                                                <div onClick={() => handleTooltipInteraction(item._id)} onMouseEnter={() => handleMouseEnter(item._id)} onMouseLeave={() => handleMouseLeave(item._id)}>
                                                                                    <Tooltip open={tooltipOpen[item._id] || false} title={<Typography sx={{ fontSize: "16px" }}>{item.email}</Typography>} arrow>
                                                                                        <p style={{ marginBottom: "0px", cursor: "pointer" }}>{item.email ? <>{item.email.length > 20 ? item.email.slice(0, 15) + "..." : item.email}</> : <>Not available</>}</p>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </ClickAwayListener>
                                                                        ) : (
                                                                            <>
                                                                                <p style={{ margin: "0px" }}>***************</p>
                                                                                {!showEmail[index] && (
                                                                                    <button className="show-connect-button-employer" onClick={() => handleShowAll(index)}>
                                                                                        Show
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            ) : (
                                                                <p>{item.email && item.email.includes("@") ? item.email[0] + item.email[1] + "*****" + item.email.slice(item.email.indexOf("@")) : "Not available"}</p>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={2} md={2} container justifyContent="start" alignItems="center" style={{ overflowWrap: "anywhere", wordBreak: "break-all" }}>
                                                            {user && user.role === "Employer" ? (
                                                                userEmails && userEmails.length > 0 && userEmails.includes(item.email) ? (
                                                                    <p style={{ marginBottom: "0px" }}>{item.phone}</p>
                                                                ) : (
                                                                    <>
                                                                        {showPhone[index] ? <p style={{ marginBottom: "0px" }}>{item.phone}</p> : <p style={{ marginBottom: "0px" }}>**********</p>}
                                                                        {/* <button className="show-connect-button-employer" onClick={() => handleShowAll(index)}>Show</button> */}
                                                                        {!showPhone[index] && (
                                                                            <button className="show-connect-button-employer" onClick={() => handleShowAll(index)}>
                                                                                Show
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )
                                                            ) : (
                                                                <p>{item.phone ? "*****" + item.phone.slice(6, 9) + "**" : "Not available"}</p>
                                                            )}

                                                            {user && user.role === "Employer" ? (
                                                                loadingChat ? (
                                                                    <IoChatboxOutline
                                                                        onClick={async () => {
                                                                            if (user && (!user.organisationName || !user.organisationDescription)) {
                                                                                navigate("/myProfile/company");
                                                                                return;
                                                                            }
                                                                            if (!showEmail[index] && !showPhone[index] && (!userEmails || !userEmails.includes(item.email))) {
                                                                                const response = await axios.post("/api/employer/deductConnectCreditofemployer", { employerId: user._id });
                                                                                if (response.data.message === "Please Buy Connects.") {
                                                                                    // toast.error('Please buy connects.');
                                                                                    setShowModal(true);
                                                                                    return;
                                                                                }
                                                                                await ChatToUser(item);
                                                                                if (response.data.message !== "Please Buy Connects.") {
                                                                                    try {
                                                                                        await axios.post("/api/employer/saveUserEmailInEmployer", {
                                                                                            employerId: user._id,
                                                                                            userEmail: currentData[index].email,
                                                                                        });
                                                                                    } catch (error) {
                                                                                        console.log("Error:", error);
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                await ChatToUser(item);
                                                                            }
                                                                        }}
                                                                        className="chatIconOfemp ml-auto formobilescreenchaticon"
                                                                        style={{
                                                                            fontSize: "22px",
                                                                            color: "rgb(6, 61, 180)",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <IoChatboxOutline
                                                                        onClick={async () => {
                                                                            if (user && (!user.organisationName || !user.organisationDescription)) {
                                                                                navigate("/myProfile/company");
                                                                                return;
                                                                            }
                                                                            if (!showEmail[index] && !showPhone[index] && (!userEmails || !userEmails.includes(item.email))) {
                                                                                const response = await axios.post("/api/employer/deductConnectCreditofemployer", { employerId: user._id });
                                                                                if (response.data.message === "Please Buy Connects.") {
                                                                                    // toast.error('Please buy connects.');
                                                                                    setShowModal(true);
                                                                                    return;
                                                                                }
                                                                                await ChatToUser(item);
                                                                                if (response.data.message !== "Please Buy Connects.") {
                                                                                    try {
                                                                                        await axios.post("/api/employer/saveUserEmailInEmployer", {
                                                                                            employerId: user._id,
                                                                                            userEmail: currentData[index].email,
                                                                                        });
                                                                                    } catch (error) {
                                                                                        console.log("Error:", error);
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                await ChatToUser(item);
                                                                            }
                                                                        }}
                                                                        className="chatIconOfemp ml-auto formobilescreenchaticon"
                                                                        style={{
                                                                            fontSize: "22px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                )
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <hr style={{ border: "1px solid #dadada", width: "100%", height: "1px" }} />
                                                    </Grid>
                                                </div>
                                            ));
                                        })()}
                                    </>
                                ) : (
                                    <></>
                                )}

                                {/* Pagination component */}
                                <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{ marginBottom: "1%", paddingRight: "5%" }}>
                                    {pageCount > 1 ? (
                                        <>
                                            {/* 
          <MuiPagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            //   shape="rounded"
            variant="outlined"
            className="mb-4"
          />
          */}
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ marginTop: "30px" }}></div>
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}
                    </div>
                </div>
                {pageCount > 1 ? (
                    <>
                        {/* <div className="pagination" style={{ marginTop: "-20px" }}>
                        <p onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <IoIosArrowBack className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                        <span style={{ fontSize: "16px" }}>
                          {currentPage} / {pageCount}
                        </span>
                        <p onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                          <IoIosArrowForward className= {currentPage === pageCount ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                      </div> */}
                        {/* <div className="pagination" style={{ marginTop: "-20px" }}>
              <p
                onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn"}
              >
                <IoIosArrowBack style={{ marginTop: "6px" }} />
              </p>
              <span style={{ fontSize: "16px" }}>
                {currentPage} / {pageCount}
              </span>
              <p
                onClick={() => currentPage < pageCount && handlePageChange(currentPage + 1)}
                className={currentPage === pageCount ? "paginationBtndisabled" : "paginationBtn"}
              >
                <IoIosArrowForward style={{ marginTop: "6px" }} />
              </p>
            </div> */}
                        <div
                            className=""
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                // marginBottom: "50px",
                                maxWidth: "1200px",
                                width: "90%",
                                overflow: "auto",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Pagination totalPages={pageCount} currentPage={currentPage} onPageChange={setCurrentPage} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <Popup open={showModal} closeOnDocumentClick onClose={() => setShowModal(false)}>
                <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                    <div className="popup-containernew">
                        <div className="close-button12345" onClick={() => setShowModal(false)}>
                            <RxCross1 />
                        </div>
                        <div className="success-message mx-3">Your Connect credit limit has been reached.</div>
                        <div className="success-message1 mx-3">To continue posting, kindly purchase more Connects.</div>
                        <div className="d-flex flex-column flex-md-row gap-5" style={{ marginTop: "10px", marginBottom: "20px" }}>
                            <button type="button" className="submit-btn12345" onClick={handleBuyConnects}>
                                Buy Connects
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default CandidateDetail;
